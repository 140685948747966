<!-- Control Multi-Finder -->

<template>
  <!-- <v-layout class="multi_filter"> -->
  <div style="display:flex">
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <!-- Tipo de Busqueda: Texto -->
        <v-text-field
          :style="sty"
          v-bind="$input"
          v-model="inputText"
          :label="seleccion.label"
          :disabled="disabled"
          append-icon="mdi-close-circle-outline"
          @keypress.13="$emit('buscar')"
          @click:append="limpia">
        
        <!-- append-icon="mdi-close-circle-outline" -->
          <template v-slot:prepend-inner>
            <v-icon v-on="on">{{ "mdi-menu-down" }}</v-icon>
          </template>
        </v-text-field>
      </template>

      <!-- Lista de opciones de Busqueda -->
      <v-list>
        <div style="text-align:center"> Seleccione filtro </div>
        <v-divider></v-divider>
        <v-list-item v-for="(item, index) in items" :key="index">
          <v-list-item-title @click="newSeleccion(item)">{{ item.label }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
  <!-- </v-layout> -->
</template>

<script>
export default {
  props: {
    sty: { type: String, default: "" },
    items: { type: [Object, Array], required: true },
    schema: { type: [Object, Array], required: true },
    searchFn: { type:Function, default: null },
    disabled: { type: Boolean, default: false }
  },

  data() {
    return {
      seleccion: "",
      valor: ""
      // variables de configuración
      // Entorno: this.$cfg.ctrls.multifilter,
    };
  },

  created() {
    if (!this.items) return;
    this.newSeleccion(this.items[0]);
  },


  computed: {
    inputText: {
      get: function() {
        return this.valor;
      },
      set: function(modifiedValue) {
        this.valor = modifiedValue;
        this.schema.ctrls[this.seleccion.ctrl].value=modifiedValue;
        this.$emit("input", modifiedValue);
      }
    }
  },
    
  methods: {
      // actualizo el value del texto introducido en el textfield
      newSeleccion(item) {
        if (this.seleccion != "") this.schema.ctrls[this.seleccion.ctrl].value = "";
        this.seleccion = item;
        this.schema.ctrls[this.seleccion.ctrl].value = this.valor;
      },
      limpia(){
        this.inputText="";
      }
   
    }
};

 
</script>

<style scope>
/* .multi_filter .boton_busqueda {
  margin: 4px 0px 0px 10px;
} */
</style>
